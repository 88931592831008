import { Button, Form, Input, InputNumber, message, Select, Tooltip } from 'antd';
import React from 'react';
import { DataCollRowFragment } from './types/DataCollRowFragment';


interface MeasureFormProps {
  measure: DataCollRowFragment;
  areaTotal?: number;
  handleSaveMeasure: (key: string, scenario: string, index: number) => void;
  isLoading: boolean;
  handleChangeMeasureFormItem: (
    key: string,
    scenario: string,
    index: number,
    label: string,
    value: any
  ) => void;
}

const MeasureForm: React.FC<MeasureFormProps> = ({
  measure,
  areaTotal,
  handleSaveMeasure,
  isLoading,
  handleChangeMeasureFormItem,
}) => {

  return (
    <>
      <Form
        layout='inline'
        onFinish={() => handleSaveMeasure(measure.key, measure.scenario, measure.index)}
      >
        {measure.data?.map((formItem) => (
          <Form.Item
            key={`${measure.key}-${measure.scenario}-${measure.index}-${formItem.label}`}
            label={formItem.label}
            name={formItem.label}
            initialValue={formItem.value}
            tooltip={formItem.description && formItem.description}
            style={{ lineHeight: '40px' }}
            // rules={
            //   formItem.type === 'number'
            //     ? [
            //         {
            //           type: 'number',
            //           ...(formItem.validation?.min !== undefined && { min: formItem.validation.min }),
            //           ...(formItem.validation?.max !== undefined && { max: formItem.validation.max }),
            //           message: `Value must be between ${formItem.validation?.min ?? '-∞'} and ${formItem.validation?.max ?? '∞'}`,
            //         },
            //       ]
            //     : []
            // } // not applicable here because of very custom form controlling
          >
            {formItem.type === 'number' && (
              <Tooltip title={formItem.label === "Kosten (€)" && areaTotal && `Kosten (€)/m2: ${formItem.value ? (formItem.value / areaTotal).toFixed(2) : ''}`}>
                <InputNumber
                  value={formItem.value}
                  {...(formItem.validation?.min !== undefined && { min: formItem.validation.min })}
                  {...(formItem.validation?.max !== undefined && { max: formItem.validation.max })}
                  onBlur={(e) => {
                    const trimmedValue = e.target.value.trim();
                    if(trimmedValue === '' || trimmedValue === null || trimmedValue === undefined) { 
                      return;
                    }
                    const value = Number(trimmedValue);
                    if (isNaN(value)) {
                      message.error(`Ungültiger Wert für ${formItem.label}: ${trimmedValue}. Bitte geben Sie eine Zahl ein.`, 6);
                      return;
                    }

                    if (formItem.validation?.min !== undefined && value < formItem.validation.min) {
                      message.warning(`Der Wert für ${formItem.label} wurde auf das Minimum von ${formItem.validation.min} gesetzt.`, 6);
                    }
                    if (formItem.validation?.max !== undefined && value > formItem.validation.max) {
                      message.warning(`Der Wert für ${formItem.label} wurde auf das Maximum von ${formItem.validation.max} gesetzt.`, 6);
                    }
                  }}
                  changeOnWheel
                  onChange={(value) =>
                    handleChangeMeasureFormItem(
                      measure.key,
                      measure.scenario,
                      measure.index,
                      formItem.label,
                      value
                    )
                  }
                />
              </Tooltip>
            )}
            {formItem.type === 'selection' && formItem.options && (
              <Select
                showSearch
                style={{ width: 250 }}
                value={formItem.value}
                options={formItem.options.map((option) => ({
                  value: option,
                  label: option,
                }))}
                popupMatchSelectWidth={true}
                onChange={(value) =>
                  handleChangeMeasureFormItem(
                    measure.key,
                    measure.scenario,
                    measure.index,
                    formItem.label,
                    value
                  )
                }
                allowClear
              > {formItem.options.map((option) => (
                <Select.Option key={option} value={option}>
                  <Tooltip title={option}>
                    {option}
                  </Tooltip>
                </Select.Option>
              ))}
              </Select>
            )}
            {(formItem.type === 'text' ||
              (formItem.type === 'selection' && !formItem.options)) && (
                <Input
                  value={formItem.value}
                  onChange={(e) =>
                    handleChangeMeasureFormItem(
                      measure.key,
                      measure.scenario,
                      measure.index,
                      formItem.label,
                      e.target.value
                    )
                  }
                allowClear
                />
              )}
          </Form.Item>
        ))}
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading} style={{ marginTop: "5px" }}>
            Speichern
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default MeasureForm;
