/* eslint-disable @typescript-eslint/no-unused-vars */
// TODO: enable no-unused-vars
import React, { useEffect, useState } from 'react';
import { Button, Card, Form, Input, InputNumber, List, message, Modal, Select, Tooltip, Typography } from 'antd';

import { apiUrl } from '../../common/url';
import axios from 'axios';
import * as Sentry from "@sentry/react";
import { FormItemData, FormItemWithoutValue } from '../../common/types/FormItemData';
import ReportAdministration from './ReportAdministration';

interface ReportType {
  report_id: string;
  client_id: number;
  report_name: string;
}

interface ClientType {
  client_id: number;
  name: string;
}

interface ClientWithDataType extends ClientType {
  data?: Array<FormItemData>;
}

interface ClientAdministrationProps {
  adminRoles: string[];
  adminToken: string;
}

const ClientAdministration: React.FC<ClientAdministrationProps> = ({ adminRoles, adminToken }) => {
  const [clientWithData, setClientWithData] = useState<ClientWithDataType>();
  const [clients, setClients] = useState<ClientType[]>([]);
  const [auxClientsFields, setAuxClientsFields] = useState<FormItemWithoutValue[]>([]);
  const [highestClientId, setHighestClientId] = useState<number>();
  const [form] = Form.useForm();

  useEffect(() => {
    axios.get(`${apiUrl}/admin/manage_clients/get_all_clients`,
      { headers: { Authorization: `Bearer ${adminToken}` } })
      .then((response) => {
        setClients(response.data);
      }).catch((error) => {
        console.log(error.response.data);
        message.error('Fehler beim Laden der Kunden', 3);
        Sentry.withScope(scope => {
          scope.setLevel('warning');
          scope.setExtra('hint', 'Fehler beim Laden der Kunden');
          Sentry.captureException(error);
        });
      });
  }, [adminToken]);

  useEffect(() => {
    if (clientWithData) {
      const initialValues = clientWithData?.data?.reduce((acc: { [key: string]: any }, item) => {
        acc[item.label] = item.value;
        return acc;
      }, {});

      form.setFieldsValue(initialValues);
    }
  }, [clientWithData, form]);

  useEffect(() => {
    if (clients.length > 0) {
      const maxId = Math.max(...clients.map(client => client.client_id));
      setHighestClientId(maxId);
    }
  }, [clients]);

  useEffect(() => {
    axios.get(`${apiUrl}/admin/manage_clients/get_aux_clients_fields`,
      { headers: { Authorization: `Bearer ${adminToken}` } })
      .then((response) => {
        setAuxClientsFields(response.data);
      }).catch((error) => {
        console.log(error.response.data);
        message.error('Fehler beim Laden der Aux Clients Fields', 3);
        Sentry.withScope(scope => {
          scope.setLevel('warning');
          scope.setExtra('hint', 'Fehler beim Laden der Aux Clients Fields');
          Sentry.captureException(error);
        });
      });
  }, [adminToken]);

  const handleChangeClient = (clientId: number) => {
    const client = clients.find((client) => client.client_id === clientId);
    if (!client) return;
    axios.get(`${apiUrl}/admin/manage_clients/get_client/${client.client_id}`,
      { headers: { Authorization: `Bearer ${adminToken}` } })
      .then((response) => {
        const updatedClientWithData = {
          ...client,
          data: response.data,
        };
        setClientWithData(updatedClientWithData);

      }).catch((error) => {
        console.log(error.response.data);
        message.error('Fehler beim Laden des Kunden: ' + error.message, 3);
        Sentry.withScope(scope => {
          scope.setLevel('warning');
          scope.setExtra('hint', 'Fehler beim Laden des Kunden: ' + error.message);
          Sentry.captureException(error);
        });
      });
  };

  const createClient = (values: ClientType) => {
    const newUser = values as ClientType;
    axios.post(`${apiUrl}/admin/manage_clients/create_client`,
      newUser,
      { headers: { Authorization: `Bearer ${adminToken}` } })
      .then((response) => {
        console.log(response.data);
        message.success('Benutzer erstellt', 3);
        setClients([...clients, response.data]);
        handleChangeClient(response.data.id);
      }).catch((error) => {
        console.log(error.message);
        message.error('Fehler beim Erstellen des Kunden: ' + error.message, 3);
        Sentry.withScope(scope => {
          scope.setLevel('warning');
          scope.setExtra('hint', 'Fehler beim Erstellen des Kunden: ' + error.message);
          Sentry.captureException(error);
        });
      });
  };

  const updateClient = () => {
    if (!clientWithData?.client_id) {
      message.error('Fehler beim Aktualisieren des Kunden: Kunden ID ist nicht verfügbar. Bitte Seite aktualisieren und erneut versuchen.', 3);
      return;
    }

    const updatedUserData = clientWithData?.data?.reduce((obj, item) => {
      return {
        ...obj,
        [item.label]: item.value,
      };
    }, {});

    axios.post(`${apiUrl}/admin/manage_clients/update_client/${clientWithData.client_id}`,
      updatedUserData,
      { headers: { Authorization: `Bearer ${adminToken}` } })
      .then((response) => {
        message.success('Kunde aktualisiert', 3);
      }).catch((error) => {
        console.log(error.message);
        message.error('Fehler beim Aktualisieren des Kunden: ' + error.message, 3);
        Sentry.withScope(scope => {
          scope.setLevel('warning');
          scope.setExtra('hint', 'Fehler beim Aktualisieren des Kunden: ' + error.message);
          Sentry.captureException(error);
        });
      });
  };

  const handleChangeClientField = (clientId: number, label: string, value: string | number) => {
    if (!clientWithData) return;
    const updatedFields = clientWithData.data?.map((formItem) => {
      if (formItem.label === label) {
        return { ...formItem, value };
      }
      return formItem;
    });
    setClientWithData({ ...clientWithData, data: updatedFields });
  }

  const handleDeleteDialog = () => {
    Modal.confirm({
      title: 'Soll dieser Kunde wirklich gelöscht werden?',
      content: `Kunde: ${clientWithData?.name} (${clientWithData?.client_id}); Diese Aktion kann nicht rückgängig gemacht werden.`,
      onOk() {
        handleDelete();
      },
      onCancel() {
        message.info('Löschen abgebrochen', 3);
      },
    });
  };

  const handleDelete = () => {
    if (!clientWithData?.client_id) {
      message.error('Fehler beim Löschen des Kunden: Kunde ID ist nicht verfügbar. Bitte zunächste einen Kunde auswählen.', 3);
      return;
    }
    axios.post(`${apiUrl}/admin/manage_clients/delete_client/${clientWithData.client_id}`, null,
      { headers: { Authorization: `Bearer ${adminToken}` } })
      .then((response) => {
        console.log(response.data);
        message.success('Kunde gelöscht', 3);
        setClients(clients.filter(client => client.client_id !== clientWithData.client_id));
        setClientWithData(undefined);
      }).catch((error) => {
        console.log(error.message);
        message.error('Fehler beim Löschen des Kunden: ' + error.message, 3);
        Sentry.withScope(scope => {
          scope.setLevel('warning');
          scope.setExtra('hint', 'Fehler beim Löschen des Kunden: ' + error.message);
          Sentry.captureException(error);
        });
      });
  }

  return (
    <>
      <Typography.Title level={3}>Kunden verwalten</Typography.Title>
      <Select
        showSearch
        style={{ width: 300, marginBottom: "20px" }}
        placeholder="Kunde auswählen"
        optionFilterProp="label"
        onChange={(value) => handleChangeClient(value)}
        options={clients.map((client) => ({ label: `${client.name} (${client.client_id})`, value: client.client_id }))}
      />
      <br />
      {clientWithData && <>
        <Card title="Kunde bearbeiten" style={{ width: "800px" }}>
          <Form
            form={form}
            name="edit-client"
            labelCol={{ span: 6 }}
            // wrapperCol={{ span: 6 }}
            autoComplete="off"
            onFinish={updateClient}
          >
            {clientWithData?.data?.map((formItem) => (
              <Form.Item
                key={`${clientWithData.name}-${formItem.label}`}
                label={formItem.label}
                name={formItem.label}
                initialValue={formItem.value}
                tooltip={formItem.description && formItem.description}
                rules={formItem.isRequired ? [{ required: true, message: 'Bitte einen Wert eintragen!' }] : []}
              >
                {/* TODO: maybe add chgeckbox type */}
                {formItem.type === 'number' && (
                  <InputNumber
                    value={formItem.value}
                    {...(formItem.validation?.min !== undefined && { min: formItem.validation.min })}
                    {...(formItem.validation?.max !== undefined && { max: formItem.validation.max })}
                    onBlur={(e) => {
                      const trimmedValue = e.target.value.trim();
                      if(trimmedValue === '' || trimmedValue === null || trimmedValue === undefined) { 
                        return;
                      }
                      const value = Number(trimmedValue);
                      if (isNaN(value)) {
                        message.error(`Ungültiger Wert für ${formItem.label}: ${trimmedValue}. Bitte geben Sie eine Zahl ein.`, 6);
                        return;
                      }
  
                      if (formItem.validation?.min !== undefined && value < formItem.validation.min) {
                        message.warning(`Der Wert für ${formItem.label} wurde auf das Minimum von ${formItem.validation.min} gesetzt.`, 6);
                      }
                      if (formItem.validation?.max !== undefined && value > formItem.validation.max) {
                        message.warning(`Der Wert für ${formItem.label} wurde auf das Maximum von ${formItem.validation.max} gesetzt.`, 6);
                      }
                    }}
                    onChange={(value) =>
                      handleChangeClientField(
                        clientWithData.client_id,
                        formItem.label,
                        value
                      )
                    }
                    style={{ width: 400 }}
                  />
                )}
                {formItem.type === 'selection' && formItem.options && (
                  <Select
                    showSearch
                    style={{ width: 400 }}
                    value={formItem.value}
                    options={formItem.options.map((option) => ({
                      value: option,
                      label: option,
                    }))}
                    popupMatchSelectWidth={true}
                    onChange={(value) =>
                      handleChangeClientField(
                        clientWithData.client_id,
                        formItem.label,
                        value
                      )
                    }
                  > {formItem.options.map((option) => (
                    <Select.Option key={option} value={option}>
                      <Tooltip title={option}>
                        {option}
                      </Tooltip>
                    </Select.Option>
                  ))}
                  </Select>
                )}
                {(formItem.type === 'text' ||
                  (formItem.type === 'selection' && !formItem.options)) && (
                    <Input
                      value={formItem.value}
                      onChange={(e) =>
                        handleChangeClientField(
                          clientWithData.client_id,
                          formItem.label,
                          e.target.value
                        )
                      }
                      style={{ width: 400 }}
                    />
                  )}
              </Form.Item>
            ))}
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Änderungen speichern
              </Button>
              <Button danger onClick={handleDeleteDialog} style={{ marginLeft: "10px" }}>
                Kunde löschen
              </Button>
            </Form.Item>
          </Form>
          <ReportAdministration client={clientWithData} adminToken={adminToken} />
        </Card>
        <br />
      </>}
      <br />
      {clients && highestClientId && <Card title="Kunde anlegen" style={{ width: "800px" }}>
        <Form
          name="new-client"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 6 }}
          autoComplete="off"
          onFinish={(values) => createClient(values)}
          initialValues={{ client_id: highestClientId + 1 }}
        >

          {auxClientsFields.map((formItem) => (
            <Form.Item
              key={`new-client-${formItem.label}`}
              label={formItem.label}
              name={formItem.label}
              tooltip={formItem.description && formItem.description}
              rules={formItem.isRequired ? [{ required: true, message: 'Bitte einen Wert eintragen!' }] : []}
            >
              {/* TODO: maybe add chgeckbox type */}
              {formItem.type === 'number' && (
                <InputNumber
                  {...(formItem.validation?.min !== undefined && { min: formItem.validation.min })}
                  {...(formItem.validation?.max !== undefined && { max: formItem.validation.max })}
                  onBlur={(e) => {
                    const trimmedValue = e.target.value.trim();
                    if(trimmedValue === '' || trimmedValue === null || trimmedValue === undefined) { 
                      return;
                    }
                    const value = Number(trimmedValue);
                    if (isNaN(value)) {
                      message.error(`Ungültiger Wert für ${formItem.label}: ${trimmedValue}. Bitte geben Sie eine Zahl ein.`, 6);
                      return;
                    }

                    if (formItem.validation?.min !== undefined && value < formItem.validation.min) {
                      message.warning(`Der Wert für ${formItem.label} wurde auf das Minimum von ${formItem.validation.min} gesetzt.`, 6);
                    }
                    if (formItem.validation?.max !== undefined && value > formItem.validation.max) {
                      message.warning(`Der Wert für ${formItem.label} wurde auf das Maximum von ${formItem.validation.max} gesetzt.`, 6);
                    }
                  }}
                  changeOnWheel
                  style={{ width: 400 }}
                />
              )}
              {formItem.type === 'selection' && formItem.options && (
                <Select
                  showSearch
                  style={{ width: 400 }}
                  options={formItem.options.map((option) => ({
                    value: option,
                    label: option,
                  }))}
                  popupMatchSelectWidth={true}
                > {formItem.options.map((option) => (
                  <Select.Option key={option} value={option}>
                    <Tooltip title={option}>
                      {option}
                    </Tooltip>
                  </Select.Option>
                ))}
                </Select>
              )}
              {(formItem.type === 'text' ||
                (formItem.type === 'selection' && !formItem.options)) && (
                  <Input
                    style={{ width: 400 }}
                  />
                )}
            </Form.Item>
          ))}
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Kunde anlegen
            </Button>
          </Form.Item>
        </Form>
      </Card>}
    </>
  );
};

export default ClientAdministration;
